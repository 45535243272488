@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;700&family=JetBrains+Mono:wght@100&display=swap');

html, body {
  background-color: black;
  cursor: url('./assets/images/cursor.png') 5 5, auto;
  color: #cccccc;
  font-family: 'Jura', sans-serif;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  scroll-behavior: smooth;
  scrollbar-color: rgba(255, 255, 255, 0.25) rgba(50, 50, 50, 0.3);
  width: 100%;
}

a, button {
  cursor: url('./assets/images/cursor-active.png') 5 5, auto;
}

* {
  box-sizing: border-box;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Scroll bar styles */
::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.25);
}

/* Custom SVG icons */
svg.icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  stroke: currentColor;
  stroke-width: 0;
  vertical-align: text-bottom;
  overflow: hidden;
}
.icon-from-src-wrapper {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: text-bottom;
  overflow: hidden;
}
.icon-from-src-wrapper > svg {
  fill: currentColor;
  height: 100%;
  stroke: currentColor;
  stroke-width: 0;
  width: 100%;
}

.__react_component_tooltip.place-top::before {
  display: none !important;
}
